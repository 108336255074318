<template>
  <v-data-table
    :headers="headers"
    :items="$store.state.customers"
    sort-by="firstName"
    item-key="id"
    class="elevation-1 border"
    :search="search"
    :loading="tableLoading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Manage Customers</v-toolbar-title>
        <v-divider class="mx-4" inset vertical> </v-divider>

        <v-dialog
          v-model="dialog"
          scrollable
          max-width="500px"
          :persistent="formCustomerLoading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :disabled="tableLoading"
              :retain-focus-on-click="false"
              :fab="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon class="mx-2"> mdi-account-plus </v-icon>
              <span v-if="!$vuetify.breakpoint.mdAndDown">
                Add new customer
              </span>
            </v-btn>
          </template>

          <FormCustomer
            v-if="dialog"
            :dataz="editingCustomer"
            :disabled="formCustomerLoading"
            :titleText="formCustomerTitleText"
            :saveText="formCustomerSaveText"
            :company="company"
            @save="saveCustomer"
            @cancel="closeForm"
          />
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete"> Cancel </v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- End Edit/New Dialogue -->
        <v-dialog v-model="dialogUpload" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :disabled="tableLoading"
              :fab="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon class="mx-2">mdi-cloud-upload</v-icon>
              <span v-if="!$vuetify.breakpoint.mdAndDown">
                Upload CSV File
              </span>
            </v-btn>
          </template>

          <v-card class="border">
            <v-card-title>
              <span class="text-h5">Upload Customers</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <input
                      class="d-flex justify-start"
                      type="file"
                      id="file"
                      ref="myFiles"
                      accept=".csv"
                      @change="getBase64()"
                    />
                  </v-col>
                <v-col cols="12" sm="12" md="12" class="ma-0 pt-0 pb-0">
                <v-checkbox
                  v-model="notifyBrands"
                  label="Notify brands"
                >
              </v-checkbox>
            </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeUpload()">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  batchUpload();
                  closeUpload();
                "
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete()">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm()"
                >OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Preview Card Dialog Start -->
        <v-dialog
          v-model="previewDialog"
          max-width="500px"
          :retain-focus="false"
          :persistent="loadingPreviewCard"
        >
          <v-card class="border" style="overflow: hidden">
            <v-card-title>
              <span class="text-h5">Card Preview</span>
              <v-col class="d-flex flex-row-reverse">
                <v-btn color="primary" fab small @click="closeCardPreview()" :disabled="loadingPreviewCard">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
			<div class="pa-5">
			<v-container>
				<v-select
					label="Card Group"
					v-model="selectedPreviewCard"
					:items="customerCards"
					item-text="name"
					return-object
				></v-select>
        <v-select
          label="Definition key"
          v-model="selectedDefinitionKey"
          :items="$store.state.definitions"
          item-text="definitionKey"
        ></v-select>
			</v-container>
            <v-container>
              <v-row justify="center" align="center" no-gutters>
                <v-col cols="12" md="12" lg="12" sm="12" xs="12">
                  <v-skeleton-loader
                    v-if="loadingPreviewCard"
                    class="mx-auto"
                    max-width="300"
                    type="card"
                  >
                  </v-skeleton-loader>
                  <img v-else :src="output" width="100%" />
                  <v-chip
                    v-if="!previewFormActionsDisabled"
                    class="pa-2"
                    color="success"
                  >
                    <h4>
                      <v-icon>{{ date ? 'mdi-email-check' : 'mdi-email' }}</v-icon>
                      <span class="px-2">{{ date ? date : 'Card not sent' }}</span>
                    </h4>
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
			</div>

            <v-row class="pa-2" justify="center" align="center">
              <!-- <v-card-actions> -->
              <v-col class="d-flex flex-row">
                <v-btn color="primary" @click="sendEmailCard()" :disabled="sendEmailDisabled">
                  <v-icon class="mr-2">mdi-send</v-icon>
                  Send
                </v-btn>
                <v-btn color="primary" class="mx-2" @click="downloadPdf()" :disabled="previewFormActionsDisabled">
                  <v-icon class="mr-2">mdi-download</v-icon>
                  Download
                </v-btn>
                <v-btn color="primary" @click="preview()" :disabled="sendEmailDisabled">
                  <v-icon class="mr-2">mdi-eye</v-icon>
                  Preview
                </v-btn>
                <v-icon v-if="previewStatusIcon" class="ml-2">{{ previewStatusIcon }}</v-icon>
              </v-col>
              <!-- @click="save()" -->
              <!-- </v-card-actions> -->
            </v-row>
          </v-card>
        </v-dialog>
        <!-- Preview Card Dialog End -->

        <!-- Start Error Dialog -->
        <v-dialog v-model="errorDialog" max-width="500px">
          <v-card>
            <v-alert
              :type="type"
              prominent
              border="left"
              dismissable
              close-text="Close Alert"
              class="ma-0"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ errorMessage }}
                </v-col>
                <v-col class="shrink">
                  <v-btn color="white" text @click="errorDialog = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-dialog>
        <!-- End Error Dialog -->
      </v-toolbar>

      <v-text-field
        class="pa-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      >
      </v-text-field>
    </template>
	<template v-slot:item.cards="{ item }">
      {{ cardsToString(item.cards) }}
	</template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

      <v-icon small class="mr-2" @click="previewCard(item)"> mdi-eye </v-icon>

      <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      <!-- <v-icon small class="mr-2" @click="print()"> mdi-printer </v-icon>
			<v-icon small class="mr-2" @click="deleteItem(item)"> mdi-mail </v-icon> -->
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize()"> Reset </v-btn>
    </template>

    <template v-slot:item.discounts="{ item }">
      <v-chip v-for="(key, i) in item.discounts" :key="i" color="green" dark>
        {{ item.discounts[i].brand }} {{ item.discounts[i].percentage + "%" }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import FormCustomer from "./FormCustomer.vue";

export default {
  components: {
    FormCustomer,
  },
  data: () => ({
    dropDownItems: [],
    dropDownOptions: [],
    brandsArray: {},
    barcodeValue: "123456789123",
    output: null,
    date: null,
    displayValue: true,
    background: "transprent",
    logo: require("@/assets/cardDesign.jpg"),
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    previewDialog: false,
    previewCustomer: null,
    search: "",
    files: [],
    reader: "",
    uploadGroupName: "",
    emailRules: [],
    emailValid: false,
    firstNameValid: false,
    lastNameValid: false,
    companyNameValid: false,
    groupNameValid: false,
    headers: [
      {
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Company", value: "company" },
      { text: "Group Names", value: "cards" },
      { text: "Assigned by", value: "assignedBy.email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    discountAffiliates: ["Calzedonia", "Tezenis", "SignorVino"],
    editedIndex: -1,
    editedItem: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      groupName: "",
      timeOfLastSentEmail: "",
      card: {
        name: "",
        barcode: "",
        discounts: [
          {
            brand: "",
            percentage: "",
          },
        ],
      },
    },
    defaultItem: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      groupName: "",
      timeOfLastSentEmail: "",
      card: {
        name: "",
        barcode: "",
        discounts: [
          {
            brand: "",
            percentage: "",
          },
        ],
      },
    },
    customer: {
      id: "",
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      groupName: "",
      card: {
        barcode: "",
        discounts: [
          {
            brand: "",
            percentage: "",
          },
        ],
      },
    },
    tableLoading: false,
    errorDialog: false,
    errorMessage: "",
    type: "",
    openCustomerForm: false,
    editingCustomer: null,
    formCustomerLoading: false,
    selectedPreviewCard: null,
    loadingPreviewCard: false,
    selectedDefinitionKey: null,
    notifyBrands: true,
    previewStatusIcon: null,
    company: null
  }),

  computed: {
      formCustomerTitleText() {
        return this.editingCustomer ? "Edit Customer" : "New Customer";
      },
      formCustomerSaveText() {
        return this.editingCustomer ? "Save" : "Create";
      },
    customerCards() {
      let cards = [];
      if (this.previewCustomer !== null) {
        cards = this.$store.getters.customerCards(this.previewCustomer.id);
      }
      return cards;
    },
    previewFormActionsDisabled() {
      return this.output === null || this.loadingPreviewCard;
    },
    sendEmailDisabled() {
      return this.previewFormActionsDisabled || !this.selectedDefinitionKey;
    }
  },
  watch: {
    previewDialog(val) {
      val || this.resetPreview();
    },
    dialog(val) {
      val || this.closeForm();
    },
    dialogUpload(val) {
      val || this.closeUpload();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dropDownOptions(val) {
      this.dropDownOptions = val;
      this.brandsArray = val;
      //this.drop(val);
    },
    selectedPreviewCard(card) {
    if (card) {
      this.generateCard(card);
    }
	},
  previewCustomer() {
      this.output = null;
      this.selectedPreviewCard = null;
      this.selectedDefinitionKey = null;
    }
  },
  async created() {
    await this.$store.dispatch("getDefinitions", {
            token: await this.$auth.getAccessToken()
    });
    await this.me();
  },

  methods: {
    async preview() {
		this.previewStatusIcon = 'mdi-email-arrow-right-outline';
		axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						previewEmail(previewEmailInput: { cardGroup:"${this.selectedPreviewCard.name}",definitionKey: "${this.selectedDefinitionKey}" })
						{
							success
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.previewEmail);
            if (response.data.data.previewEmail.success) {
				this.previewStatusIcon = 'mdi-check';
            } else {
				this.previewStatusIcon = 'mdi-alert-circle-outline';
			}
          }
        }).catch(() => {
			this.previewStatusIcon = 'mdi-alert-circle-outline';
		});
    },
    async me() {
      axios
        .post(
          this.$apiUrl,
          {
            query: `query {me { email }}`
          },
          {
            headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
            }
          }
        )
        .then(response => {
          const email = response.data.data.me.email;
          if (email.endsWith('@falconeri.it')) {
            this.company = 'FLC_Commercial_Department';
          }
        });
    },
    async saveCustomer({ customer, notifyBrands }) {
      try {
        this.tableLoading = true;
        this.formCustomerLoading = true;
        if (this.editingCustomer) {
          await this.$store.dispatch("updateCustomer", {
            token: await this.$auth.getAccessToken(),
            customer,
            notifyBrands
          });
        } else {
          await this.$store.dispatch("createCustomer", {
            token: await this.$auth.getAccessToken(),
            customer,
            notifyBrands
          });
        }
		this.dialog = false;
      } catch (err) {
        this.errorMessage = err;
        this.type = "error";
        this.errorDialog = true;
        this.tableLoading = false;
        this.selectedGroups = [];
        this.brandsArray = {};
      } finally {
        this.tableLoading = false;
        this.formCustomerLoading = false;
      }
    },
    drop(val) {
      if (this.dropDownOptions !== "") {
        var arr = this.dropDownItems.find((obj) => {
          return obj.name === val;
        });
        this.dropDownOptions = arr.name;
        console.log(arr);
        this.brandsArray = arr;
        // console.log("Arr", arr["0"].discounts);
      }
    },
    cardsToString(cards) {
      return cards.map((c) => c.name).join(", ");
    },
    resetPreview() {
      this.editedItem = this.defaultItem;
    },
    async print() {
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        imageSmoothingEnabled: false,
        // useCORS: true
      };
      this.output = await this.$html2canvas(el, options);
    },
    getBase64() {
      this.files = this.$refs.myFiles.files;
      console.log(this.files);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0]);
        reader.onload = () => {
          resolve(reader.result);
          this.reader = reader.result.split(";")[1].split(",")[1];
        };
        reader.onerror = (error) => reject(error);
        console.log(reader);
      });
    },
    async batchUpload() {
      // for (var i = 0; i < this.uploadCustomer.length; i++) {
      //   // this.uploadCustomer[i].groupName = this.uploadGroupName;
      //   this.$store.state.customers.push(this.uploadCustomer[i]);
      // }
      this.tableLoading = true;
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						bulkCreateCustomer(bulkCustomerImportInput: {file: "${this.reader}", clearDb:false, notifyBrands: ${this.notifyBrands}}){
							customers{
								id
								firstName
								lastName
								email
								company
								cards{
									id
									status
									name
									barcode
									discounts{
										id
										brand
										percentage
									}
								}
							}
						}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then(async (response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.bulkCreateCustomer.customers);
            await this.$store.dispatch("getCustomers", {
              token: await this.$auth.getAccessToken()
            });
            this.tableLoading = false;
          } else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.type = "error";
            this.errorDialog = true;
            this.tableLoading = false;
          }
          this.$refs.myFiles.value = null;
        });
    },
    cleanIt(obj) {
      var cleaned = JSON.stringify(obj, null, 2);
      console.log(cleaned);
      return cleaned.replace(/^[\t ]*"[^:\n\r]+(?!\\)":/gm, function (match) {
        return match.replace(/"/g, "");
      });
    },
    async generateCard(card) {
      this.loadingPreviewCard = true;
    
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						generateCard(cardGenerationInput: {id: "${card.id}"})
						{
							name
							content
							mimetype
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.generateCard);

            this.output =
              "data:" +
              response.data.data.generateCard.mimetype +
              ";base64, " +
              response.data.data.generateCard.content;
            this.date = card.timeOfLastSentEmail;
          } else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.type = "error";
            this.errorDialog = true;
            this.tableLoading = false;
          }
        }).finally(() => {
          this.loadingPreviewCard = false;
        });
    },
    async sendEmailCard() {
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						sendEmail(id: "${this.selectedPreviewCard.id}",definitionKey: "${this.selectedDefinitionKey}")
						{
							success
							timeOfLastSentEmail
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.sendEmail);
            if (response.data.data.sendEmail.success) {
              // alert("Email Sent!");
              var formatedTime = new Date(
                parseInt(response.data.data.sendEmail.timeOfLastSentEmail)
              ).toLocaleString();
              this.$store.state.customers[
                this.editedIndex
              ].timeOfLastSentEmail = formatedTime;
              this.closeCardPreview();
            } else {
              console.log(response.data.errors, "errors");
              this.errorMessage = response.data.errors[0].message;
              this.type = "error";
              this.errorDialog = true;
              this.tableLoading = false;
            }
          }
        });
    },
    async downloadPdf() {
      if (this.selectedPreviewCard) {
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
            generateDiscountCardPdf(id: "${this.selectedPreviewCard.id}") {
							name
							content
							mimetype
						}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            let a = document.createElement("a");
            a.href =
              "data:application/pdf;base64, " +
              response.data.data.generateDiscountCardPdf.content;
            a.download = `${response.data.data.generateDiscountCardPdf.name}`;
            a.click();
            a.remove();
          } else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.type = "error";
            this.errorDialog = true;
            this.tableLoading = false;
          }
        });
      }
    },
    editItem(item) {
      this.editedIndex = this.$store.state.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.editingCustomer = Object.assign({}, item);
    },
    deleteItem(item) {
      this.editedIndex = this.$store.state.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    previewCard(item) {
      this.editedIndex = this.$store.state.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.previewCustomer = item;
      this.previewDialog = true;
    },
    closeCardPreview() {
      this.previewDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedItem = {};
        // this.editedItem = [];
        // this.editedItem.card.discounts = [];
        this.editedIndex = -1;
        this.selectedDefinitionKey = null;
        this.defaultItem.card.name = "";
      });
    },
    async deleteItemConfirm() {
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						deleteCustomer(id: "${this.editedItem.id}")
						{
							id
							firstName
							lastName
							email
							fullName
							company
							cards{
								id
								status
								name
								barcode
								discounts{
									id
									brand
									percentage
								}
							}
						}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.deleteCustomer, "response");
            // Object.assign(
            // this.$store.state.customers[index],response.data.data.updateCustomer);
            if (response.data.data.deleteCustomer !== null) {
              var index = this.$store.state.customers.findIndex(function (o) {
                return o.id === response.data.data.deleteCustomer.id;
              });
              this.$store.state.customers.splice(index, 1);
              // const updatedState = this.$store.state.customers.filter(item => item.id !== response.data.data.deleteCustomer.id);
              // this.$store.state.customers = updatedState;
              // console.log(updatedHero);
              // console.log(response.data.data.deleteCustomer);
              // if (this.$refs.form.resetValidation() !== undefined) {
              // 	this.$refs.form.resetValidation();
              // }
            }
          } else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.type = "error";
            this.errorDialog = true;
            this.tableLoading = false;
          }
        });
      this.closeDelete();
    },
    closeUpload() {
      this.dialogUpload = false;
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialogDelete = false;
      });
    },
    closeForm() {
      this.editingCustomer = null;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #007bff;
}
input {
  color: #007bff;
}
</style>
