<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ titleText }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.firstName"
                label="First Name*"
                :disabled="disabled2"
                :rules="[rules.required, rules.min]"
                required
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="customer.lastName"
                label="Last Name*"
                :disabled="disabled2"
                :rules="[rules.required, rules.min]"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="customer.email"
                :rules="[rules.required, rules.email]"
                label="Email*"
                :disabled="disabled2"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="!company">
              <v-text-field
                v-model="customer.company"
                label="Company*"
                :disabled="disabled2"
                :rules="[rules.required, rules.min]"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                :items="dropDownItems"
                v-model="selectedGroups"
                label="Group Card Name"
                :disabled="disabled2"
                item-text="name"
                item-value="discounts.brand"
                :menu-props="{ maxHeight: '400' }"
                multiple
                return-object
              >
                <!-- :rules="[rules.required, rules.check]"-->
              </v-select>
              <div v-if="selectedGroups != ''">
                <div v-for="(option, key1) in selectedGroups" :key="key1">
                  {{ option.name }}
                  <v-chip
                    small
                    v-for="(el, key2) in option.discounts"
                    :key="key2"
                    >{{ el.brand }} - {{ el.percentage }}</v-chip
                  >
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="ma-0 pt-0 pb-0">
              <v-checkbox
                v-model="notifyBrands"
                label="Notify brands"
                :disabled="cardNotAssigned"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel()" :disabled="disabled2">
        Cancel
      </v-btn>
      <v-btn color="primary" text @click="save()" :disabled="disabled2">
        {{ saveText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ["dataz", "disabled", "titleText", "saveText", "company"],
  data() {
    return {
      dropDownItems: [],
      selectedGroups: [],
      customer: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        cards: [],
      },
      notifyBrands: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length > 0 || "Min 1 character",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
        check: (v) => v !== "" || "Select one",
      },
      loading: false,
    };
  },
  computed: {
    disabled2() {
      return this.$props.disabled || this.loading;
    },
  },
  mounted() {
    const dataz = this.$props.dataz || {};
    this.customer = {
      id: dataz.id || "",
      firstName: dataz.firstName || "",
      lastName: dataz.lastName || "",
      email: dataz.email || "",
      company: dataz.company || "",
      cards: dataz.cards || [],
    };
    this.company = this.$props.company;
    console.log(this.company);
  },
  methods: {
    getGroupNames(customer) {
      this.loading = true;
      const groups = this.$store.state.availableGroups;
      const items = [];
      const selected = [];
      const insertedNames = new Set();
      for (let i = 0; i < customer.cards.length; i++) {
        const group = {
          name: customer.cards[i].name,
          discounts: customer.cards[i].discounts,
        };
        items.push(group);
        selected.push(group);
        insertedNames.add(group.name);
      }
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        if (!insertedNames.has(group.name)) {
          items.push(group);
        }
      }
      this.dropDownItems = items;
      this.selectedGroups = selected;
      this.loading = false;
    },
    save() {
      console.log(this);
      console.log("SAVED");
      console.log(this.$refs.form);
      if (this.company) {
        this.customer.company = this.company;
        this.companyNameValid = true;
      }
      if (
        this.emailValid &&
        this.firstNameValid &&
        this.lastNameValid &&
        this.companyNameValid
      ) {
        console.log('SVE VALID');
        this.$emit("save", { customer: this.customer, notifyBrands: this.notifyBrands });
        if (this.$refs.form.resetValidation() !== undefined) {
          this.$refs.form.resetValidation();
        }
      } else {
        this.$refs.form.validate();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  watch: {
    customer: function () {
      this.getGroupNames(this.customer);
    },
    ["customer.email"]: function (mail) {
      if (mail !== "") {
        this.emailRules = [
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Invalid Email address",
        ];
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            mail
          )
        ) {
          this.emailValid = true;
        } else {
          this.emailValid = false;
        }
      } else if (mail === "") {
        this.emailRules = [];
        this.emailValid = false;
      }
    },
    ["customer.firstName"]: function (name) {
      if (name !== "") {
        if (name.length > 0) {
          this.firstNameValid = true;
        } else {
          this.firstNameValid = false;
        }
      } else if (name === "") {
        this.firstNameValid = false;
      }
    },
    ["customer.lastName"]: function (name) {
      if (name !== "") {
        if (name.length > 0) {
          this.lastNameValid = true;
        } else {
          this.lastNameValid = false;
        }
      } else if (name === "") {
        this.lastNameValid = false;
      }
    },
    ["customer.company"]: function (name) {
      if (name !== "") {
        if (name.length > 0) {
          this.companyNameValid = true;
        } else {
          this.companyNameValid = false;
        }
      } else if (name === "") {
        this.companyNameValid = false;
      }
    },
    ["customer.card.name"]: function (name) {
      if (name !== "") {
        if (name !== "") {
          this.groupNameValid = true;
        } else {
          this.groupNameValid = false;
        }
      } else if (name === "") {
        this.groupNameValid = false;
      }
    },
    selectedGroups(val) {
      this.selectedGroups = val;
      this.brandsArray = val;
      this.customer.cards = val;
      console.log(this.selectedGroups);
      console.log(val);
    },
  },
};
</script>